// @import '~antd/dist/antd.css';
// @import '~antd/dist/antd.dark.css';
@import './theme.scss';

@media screen and (min-width: 900px) {
  .showme {
    display: none;
  }

  .showonhover:hover .showme {
    display: inline;
  }
}
